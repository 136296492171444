<template>
  <!-- 菜单添加页面 -->
  <moe-page title="菜单添加">
    <moe-form ref="menuForm" :model="menus" :rules="menuRules">
      <el-form-item label="菜单标题" prop="title">
        <el-input
          v-model.trim="menus.title"
          placeholder="请输入标题名称"
          maxlength="10"
          clearable
        />
      </el-form-item>
      <el-form-item label="上级菜单" prop="superId">
        <el-select v-model="supers" placeholder="请选择上级菜单" filterable clearable value-key="id"
          @change="(e) => (menus.superId = e.id)">
          <el-option :label="item.title" :value="item" v-for="item of superList" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="菜单排序" prop="sort">
        <el-input v-model.trim="menus.sort" placeholder="请输入序号" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="字体图标" prop="icon">
        <el-input v-model.trim="menus.icon" placeholder="请输入字体图标名称" maxlength="50" clearable/>
      </el-form-item>
      <el-form-item label="跳转地址" prop="path">
        <el-input v-model.trim="menus.path" placeholder="请输入菜单跳转地址" maxlength="50" clearable/>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="menuLoad"
          @click="menuInfoSubmit"
        >
          {{ menuLoad ? '保存中' : '保存' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'MenuAdd',
  data() {
    //菜单信息
    const menus = {
      superId: '', //上级菜单
      title: '', //菜单
      icon: '', //图标
      path: '', //地址
      sort: '', //排序
    };

    // 菜单数据校验
    const verify = this.$moe_verify.verifyForm;
    const menuRules = {
      // superId: verify.isEmpty('请选择上级菜单'),
      title: verify.isEmpty('请输入菜单标题'),
      icon: verify.isEmpty('请输入图标名称'),
    };

    return {
      menus, //菜单信息
      menuRules, //菜单数据校验
      supers: {}, //上级菜单
      superList: [], //上级菜单列表
      menuLoad: false, //提交状态
    };
  },
  created() {
    //获取所有菜单信息
    this.getAllMenuList();
  },
  methods: {
    /**
     * 获取所有角色信息
     **/
    getAllMenuList() {
      // 调用所有菜单API
      this.$moe_api.ROLE_API.allAuthList().then((data) => {
        if (data.code == 200) {
          this.superList = data.result;
          this.superList.unshift({
            title: "根目录"
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 提交账号信息
     **/
    menuInfoSubmit() {
      // 表单校验
      this.$refs['menuForm'].validate(() => {
        this.menuLoad = true;
        this.$moe_api.ROLE_API.menuAdd(this.menus)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.menuLoad = false;
          });
      });
    },
  },
};
</script>
